import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import ColumnsLayout from '../../../common/layout/ColumnsLayout';
import CurrencyInput from '../common/CurrencyInput';
import Uploader from '../../../common/Uploader';
import { invoiceInfoValidator } from '../claimValidators';
import useStepValidation from './useStepValidation';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import InputHelpLink from '../InputHelpLink';

function InvoiceInfoStep(props) {
  const { as: Container, claim, onChange, onNext, ...restProps } = props;

  const [errors, onClickNext] = useStepValidation(claim, onNext, invoiceInfoValidator);
  const [isDisabled, setIsDisabled] = useState(false);

  const onInputChange = useCallback((value, name) => onChange({ [name]: value }), [onChange]);

  const uploaderHelpLinkElement = (
    <InputHelpLink to="https://support.portal.sevensenders.com/support/solutions/articles/15000048062-submission-document-requirements#Invoice-Requirements%C2%A0">
      Invoice requirements
    </InputHelpLink>
  );

  return (
    <Container {...restProps} onNext={onClickNext} isDisabled={isDisabled}>
      <SidePanelContentHeader>Invoice information</SidePanelContentHeader>
      <ColumnsLayout>
        <CurrencyInput
          amount={claim.netValue}
          currency={claim.netValueCurrency}
          amountName="netValue"
          currencyName="netValueCurrency"
          label="NET_VALUE_OF_INVOICE"
          onChange={onChange}
          error={errors.netValue}
          isRequired
          noErrorTranslation
        />
      </ColumnsLayout>
      <Uploader
        name="invoiceDocument"
        files={claim.invoiceDocument}
        label="Invoice document"
        topRightElement={uploaderHelpLinkElement}
        isRequired
        errorWithTranslation={errors.invoiceDocument}
        allowedFileTypes={['application/pdf']}
        helpText="CLAIMS_INVOICE_UPLOAD_HELP_TEXT"
        onChange={onInputChange}
        onBusy={setIsDisabled}
      />
    </Container>
  );
}

InvoiceInfoStep.propTypes = {
  as: PropTypes.elementType.isRequired,
  claim: PropTypes.object.isRequired,
  isSubmitButton: PropTypes.bool,
  onChange: PropTypes.func,
};

export default React.memo(InvoiceInfoStep);
