import { Machine } from 'xstate';

export default Machine({
  key: 'Claims',
  strict: true,
  initial: 'choose-claim-type',
  states: {
    'choose-claim-type': {
      on: {
        SELECT_DAMAGE: 'choose-damage-type',
        SELECT_INVESTIGATION: 'investigation-shipping-info',
        SELECT_MISSING_ITEM: [
          {
            target: 'missing-item-warehouse-delivery-confirmation-info',
            cond: (claim) => claim.needsWarehouseDeliveryConfirmation,
          },
          {
            target: 'missing-item-shipping-info',
          },
        ],
      },
    },
    'choose-damage-type': {
      on: {
        SELECT_PARTIAL_DAMAGE: [
          {
            target: 'partial-damage-warehouse-delivery-confirmation-info',
            cond: (claim) => claim.needsWarehouseDeliveryConfirmation,
          },
          {
            target: 'partial-damage-shipping-info',
          },
        ],
        SELECT_WHOLE_DAMAGE: [
          {
            target: 'whole-damage-warehouse-delivery-confirmation-info',
            cond: (claim) => claim.needsWarehouseDeliveryConfirmation,
          },
          {
            target: 'whole-damage-shipping-info',
          },
        ],
        BACK: 'choose-claim-type',
      },
    },
    'investigation-shipping-info': {
      on: {
        BACK: 'choose-claim-type',
        NEXT: [
          {
            target: 'investigation-delivery-info',
            cond: (claim) => claim.isDelivered,
          },
          {
            target: 'investigation-invoice-info',
          },
        ],
      },
    },
    'investigation-invoice-info': {
      on: {
        BACK: [
          {
            target: 'investigation-delivery-info',
            cond: (claim) => claim.isDelivered,
          },
          {
            target: 'investigation-shipping-info',
          },
        ],
      },
    },
    'investigation-delivery-info': {
      on: {
        BACK: 'investigation-shipping-info',
        NEXT: 'investigation-invoice-info',
      },
    },
    'partial-damage-warehouse-delivery-confirmation-info': {
      on: {
        BACK: 'choose-damage-type',
        NEXT: 'partial-damage-shipping-info',
      },
    },
    'partial-damage-shipping-info': {
      on: {
        BACK: [
          {
            target: 'partial-damage-warehouse-delivery-confirmation-info',
            cond: (claim) => claim.needsWarehouseDeliveryConfirmation,
          },
          {
            target: 'choose-damage-type',
          },
        ],
        NEXT: 'partial-damage-damage-info',
      },
    },
    'partial-damage-damage-info': {
      on: {
        BACK: 'partial-damage-shipping-info',
        NEXT: 'partial-damage-invoice-info',
      },
    },
    'partial-damage-invoice-info': {
      on: {
        BACK: 'partial-damage-damage-info',
      },
    },
    'whole-damage-warehouse-delivery-confirmation-info': {
      on: {
        BACK: 'choose-damage-type',
        NEXT: 'whole-damage-shipping-info',
      },
    },
    'whole-damage-shipping-info': {
      on: {
        BACK: [
          {
            target: 'whole-damage-warehouse-delivery-confirmation-info',
            cond: (claim) => claim.needsWarehouseDeliveryConfirmation,
          },
          {
            target: 'choose-damage-type',
          },
        ],
        NEXT: 'whole-damage-damage-info',
      },
    },
    'whole-damage-damage-info': {
      on: {
        BACK: 'whole-damage-shipping-info',
        NEXT: 'whole-damage-invoice-info',
      },
    },
    'whole-damage-invoice-info': {
      on: {
        BACK: 'whole-damage-damage-info',
      },
    },
    'missing-item-warehouse-delivery-confirmation-info': {
      on: {
        BACK: 'choose-claim-type',
        NEXT: 'missing-item-shipping-info',
      },
    },
    'missing-item-shipping-info': {
      on: {
        BACK: [
          {
            target: 'missing-item-warehouse-delivery-confirmation-info',
            cond: (claim) => claim.needsWarehouseDeliveryConfirmation,
          },
          {
            target: 'choose-damage-type',
          },
        ],
        NEXT: 'missing-item-info',
      },
    },
    'missing-item-info': {
      on: {
        BACK: 'missing-item-shipping-info',
        NEXT: 'missing-item-invoice-info',
      },
    },
    'missing-item-invoice-info': {
      on: {
        BACK: 'missing-item-info',
      },
    },
  },
});
